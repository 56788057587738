<template>
  <div id="tu-teacher-find">
  	<el-row :gutter="20" v-if="step==1">
		<el-col :span="6">
			<div class="left">
				<div class="saved">
					<div>Saved tutors</div>
					<div @click="params.collect = !params.collect" style="cursor: pointer;">
						<i v-if="params.collect" class="fa fa-heart"></i>
						<i v-else class="fa fa-heart-o"></i>
					</div>
				</div>
				<el-divider></el-divider>
				<div class="tu-collapse" v-if="!params.collect">
					<el-collapse v-model="search.action">
				      <el-collapse-item name="1">
				      	<template #title>
				          <div class="tetitle">Sort</div>
				        </template>
				        <ul class="infinite-list">
				        	<li @click="search.sort=1"><span><i v-if="search.sort==1" class="fa fa-check"></i></span>Recommended</li>
				        	<li @click="search.sort=2"><span><i v-if="search.sort==2" class="fa fa-check"></i></span>Price: lowest first</li>
				        	<li @click="search.sort=3"><span><i v-if="search.sort==3" class="fa fa-check"></i></span>Price: highest first</li>
				        	<li @click="search.sort=4"><span><i v-if="search.sort==4" class="fa fa-check"></i></span>Most reviews</li>
				        	<li @click="search.sort=5"><span><i v-if="search.sort==5" class="fa fa-check"></i></span>Most lessons taught</li>
				        </ul>
				      </el-collapse-item>
				      <el-collapse-item name="2">
				      	<template #title>
				          <div class="tetitle">Keyword search</div>
				        </template>
				        <div>
						    <el-input v-model="search.keyword" size="large" placeholder="e.g. Jazz, friendly, TEFL..."></el-input>
				        </div>
				        <div style="font-size: 20px;padding: 5px 0;">
					        Search for a specific keyword or characteristic to help you find your perfect online tutor.
				        </div>
				      </el-collapse-item>
				      <el-collapse-item name="3">
				      	<template #title>
				          <div class="tetitle">Price</div>
				        </template>
				        <div style="padding: 0 20px;">
				        	<div style="font-size: 20px;line-height: 2;">{{ currency }}{{search.price[0]}} - {{ currency }}{{search.price[1]}}/hr</div>
				          	<el-slider v-model="search.price" range size="large" :min="price_range[0]" :max="price_range[1]"></el-slider>
				      	</div>
				      </el-collapse-item>
				      <el-collapse-item name="4">
						<template #title>
							<div class="tetitle">Additional filters</div>
						</template>
						<div class="filters">
							<div class="checkbox" @click="changeSearchFilters(1)">
								<span><i v-if="search.filters.indexOf(1) != -1" class="fa fa-check"></i></span>
							</div>
							<div class="text">
								<div>Background checks</div>
								<div>Tutor has a clean Enhanced DBS check dated within the last two years and proof has been provided to Tutorful.</div>
							</div>
						</div>
				      </el-collapse-item>
				    </el-collapse>
				</div>
				<div v-else>
					All the tutors you have saved from your search results will appear here.
				</div>
			</div>
		</el-col>
		<el-col :span="18">
			<div class="title">
				<div><strong>{{ params.total }}</strong> tutors</div>
			</div>
			<div class="warp" v-if="list.length > 0 || loading" v-loading="loading">
				<div class="info" v-for="item, index in list" :key="index">
					<div class="avatar">
						<el-image fit="cover" :src="item.avatar_url"></el-image>
					</div>
					<div class="name">
						<div>
							<span>{{ item.nickname }}</span>
							<el-image v-if="item.detail.background_check" :src="require('@/assets/check.png')" fit="fill"></el-image>
						</div>
						<div>
							<span>{{ item.detail.currency }}{{ item.detail.rate }}</span>
							<span>/hr</span>
						</div>
					</div>
					<div class="tagline">
						{{ item.tagline }}
					</div>
					<div class="tab">
						<el-tag type="info" v-if="item.detail.hours">{{ item.detail.hours }}</el-tag>
						<el-tag type="info" v-if="item.detail.reply">{{ item.detail.reply }}</el-tag>
					</div>
					<div class="reviews">
						<el-rate v-model="item.detail.star" disabled size="large"></el-rate>
						<div>({{ item.review_num || 0 }})</div>
					</div>
					<div class="subjects">
						<span>Teaches</span>: {{ handleSubjectString(item) }}
					</div>
					<div class="profile">
						<div @click="onGoTeacherProfile(item)">View profile <i class="fa fa-arrow-right"></i></div>
						<el-icon @click="handleCollect(index)">
							<i v-if="item.is_collect" class="fa fa-heart"></i>
							<i v-else class="fa fa-heart-o"></i>
						</el-icon>
					</div>
				</div>
				<div v-for="item, index in fill" class="info"></div>
			</div>
			<div v-else>
				<el-empty description="No Data"></el-empty>
			</div>
			<div class="tu-pagination">
				<el-pagination 
					:hide-on-single-page="true" 
					background 
					layout="prev, pager, next" 
					:total="params.total"
					:page-size="20"
					@current-change="handlePageChange">
				</el-pagination>
			</div>
		</el-col>
  	</el-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TeacherApi from '@/api/teacher'
import CommonApi from '@/api/common'
import ArrayTool from '@/utils/array-tool'

export default {
	name: "teacher",
	components: {
		
	},
	data() {
		return {
			loading: true,
			step: 1,
			setInt: null,
			list: [],
			params: {
				page: 1,
				total: 0,
				collect: false,
				// subject_id: 0
			},
			search: {
				action: 1,
				sort: 1,
				keyword: '',
				price: [0, 100],
				filters: []
			},
			show_collect: false,
			price_range: [0, 100],
			isRquest: true,
			currency: '',
			fill: 0
		}
	},
	created() {
		this.$store.dispatch('GetSubjectData')
		this.getApiListData('first')
	},
	computed: {
		...mapGetters([
			'subjectData'
		]),
	},
	watch: {
		'search.sort': {
			immediate: false,
			handler: function() {
				this.searchTutor();
			}
		},
		'search.keyword': {
			immediate: false,
			handler: function() {
				this.searchTutor();
			}
		},
		'search.price': {
			immediate: false,
			handler: function() {
				this.searchTutor();
			},
			deep: true
		},
		'params.collect': {
			immediate: false,
			handler: function() {
				this.params.page = 1;
				this.getApiListData('collect')
			},
			deep: true
		}
	},
	methods: {
		getApiListData(ckt) {
			if(!this.isRquest) {
				this.isRquest = true;
				this.loading = false;
				return ;
			}
			let params = this.params;
			if(!ckt) {
				params.sort = this.search.sort;
				params.keyword = this.search.keyword;
				params.price = this.search.price;
				params.filters = this.search.filters;
			} else {
				delete params.sort;
				delete params.keyword;
				delete params.price;
				delete params.filters;
			}
			TeacherApi.list(this.params).then(res => {
				this.list = res.data;
				this.params.total = res.meta.pagination.total;
				if(!!ckt && ckt == 'first') {
					this.isRquest = false
					this.search.price = this.price_range = res.meta.price_range;
					this.currency = res.meta.currency;
				}
				this.loading = false;
				let len = Object.keys(this.list).length;
				this.fill = 0;
				if(len > 0 && len%3 == 2) {
					this.fill = 1;
				}
			})
		},
		handlePageChange(page) {
			this.loading = true
			this.params.page = page || 1;
			this.getApiListData();
		},
		changeSearchFilters(value) {
			let index = this.search.filters.indexOf(value);
			if(index != -1) {
				this.search.filters.splice(index, 1);
			} else {
				this.search.filters.push(value);
			}
			this.searchTutor();
		},
		searchTutor() {
			let that = this;
			if(!!that.setInt) {
				clearTimeout(that.setInt);
			}
			that.setInt = setTimeout(function() {
				that.handlePageChange(1);
			}, 1000);
		},
		handleCollect(index) {
			let uid = this.list[index].uid;
			CommonApi.collect(uid).then(res => {
				this.list[index].is_collect = res.data == 1 ? 1 : 0
			})
		},
		handleSubjectString(value) {
			if(!!value.detail && !!value.detail.subject) {
				let total = 0;
				let arr = [];
				for(let i in this.subjectData.subject) {
					for(let k in this.subjectData.subject[i].children) {
						if(value.detail.subject.indexOf(parseInt(k)) != -1) {
							if(arr.length < 3) {
								arr.push(this.subjectData.subject[i].name);
							} else {
								total += 1;
							}
							break;
						}
					}
				}
				if(total > 0) {
					arr.push(' +' + total);
				}

				return arr.length > 0 ? arr.toString() : '';
			}
			return '';
		},
		onGoTeacherProfile(value) {
			this.$router.push({path: `/teacher/${value.uid}`});
		}
	}
}
</script>
<style lang="less">
#tu-teacher-find {
	.title {
		font-size: 28px;
    	line-height: 2.2;
	}

	.warp {		
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		min-height: 300px;

		.info {
			width: 32%;
			margin-bottom: 12px;
			
			.avatar {
				.el-image {
					width: 100%;
					height: 220px;
					border-radius: 5px;
				}
			}

			.name {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: baseline;
				flex-wrap: nowrap;
				line-height: 2;

				& > div:first-child {
					display: flex;
					align-items: baseline;
					flex-direction: row;

					span {
						font-size: 22px;
					}

					.el-image {
						width: 24px;
						margin-left: 8px;
					}
				}

				& > div:nth-child(2) {
					span:first-child {
						font-weight: 600;
					}
					span:nth-child(2) {
						color: var(--el-color-inifo);
					}
				}
			}

			.tagline {
				font-weight: 600;
				line-height: 1.5;
				margin: 5px 0;
				height: 46px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			.tab {
				margin: 10px 0;
    			height: 110px;

				.el-tag {
					margin: 0 5px 5px 0;
				}
			}

			.reviews {
				margin: 10px 0;
				display: flex;
    			align-items: center;

				.el-rate {
					.el-icon svg {
						width: 1.3rem;
						height: 1.3rem;
					}
				}
			}

			.subjects {
				line-height: 2;
				font-size: 13px;
			}

			.profile {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: row;

				& > div {
					background: var(--el-color-primary-light-1);
					color: var(--el-color-white);
					height: 50px;
					line-height: 50px;
					border-radius: 8px;
					padding: 0 10px;
					width: calc(100% - 85px);
					font-weight: 600;
					font-size: 18px;
					text-align: center;
					cursor: pointer;
				}

				& > div:hover {
					background: var(--el-color-primary);
				}

				.el-icon {
					font-size: 30px;
					width: 48px;
					height: 48px;
					color: var(--el-color-primary);
					border: 2px solid var(--el-color-primary);
					line-height: 48px;
					border-radius: 8px;
					cursor: pointer;
				}
			}

		}
	}

	.left {
		.el-collapse {
			border-top: 0;
		}
		.saved {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			font-size: 20px;
			font-weight: 600;
			line-height: 1;
			margin-top: 20px;
		}

		.infinite-list {
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				font-size: 20px;
				padding: 5px 10px;
				cursor: pointer;
				span {
					width: 40px;
					display: inline-block;
					text-align: center;
					color: var(--el-color-primary);
				}
			}
		}

		.tu-collapse {
			.tetitle {
				font-size: 20px;
				font-weight: 600;
			}
		}

		.filters {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
			margin: 6px 0;

			.checkbox {
				font-size: 16px;
				margin: 2px 8px;

				span {
					width: 25px;
					height: 25px;
					border: 2px solid var(--el-color-primary);
					text-align: center;
					line-height: 25px;
					color: var(--el-color-primary);
					display: inline-block;
				}
			}

			.text {
				font-size: 20px;

				& > div:first-child {
					font-weight: 600;
				}
			}
		}
	}

	.tu-pagination {
		padding: 20px 0;
	}
}
</style>